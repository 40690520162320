const INITIAL_STATE = {
	dataKontrak: {
		id: '0',
		nomor_kontrak: '',
		pnbp_type_id: '0',
		judul_kontrak: '',
		tanggal_kontrak: '',
		work_unit_id: '0',
		nama_klien: '',
		alamat_klien: '',
		nama_pic: '',
		posisi_pic: '',
		nominal_kontrak: '0',
	},
	dataDetailKontrak: {
		validate_insert: 'insert',
		kontrak: '',
		pnbp_type_id: '0',
		judul_kontrak: '',
		klien: {
			id: '',
			work_unit_id: '',
			work_unit: '',
			client_category_id: '0',
			client_category: '',
			name: '',
			address: '',
			nama_narahubung: '',
			nomor_narahubung: '',
			email_narahubung: '',
			pic_name: '',
			pic_phone: '',
			pic_position: '',
			client_address: '',
			catatan: '',
			created_by: '',
			updated_by: '',
			created_at: '',
			updated_at: '',
		},
		jasa_siaran: [],
		addtional_info: {
			discount: null,
			nomor_lampiran: null,
			lampiran: null,
		},
	},
};

const kontrak = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'DATA_KONTRAK':
			return {
				...state,
				dataKontrak: action.data,
			};
		case 'DATA_DETAIL_KONTRAK':
			return {
				...state,
				dataDetailKontrak: action.data,
			};
		case 'DATA_DEFAULT_DETAIL_KONTRAK':
			return {
				...state,
				dataDetailKontrak: INITIAL_STATE.dataDetailKontrak,
			};
		default:
			return state;
	}
};

export default kontrak;
